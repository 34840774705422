<template>
	<div>
		<div class="loader" v-loading="loading"></div>
		<div class="customForm app-modal__body pb-0" v-loading="loadingButton">
			<div v-if="!loading" class="timeline-items__right rounded-sm w-100 p-4 text-center">
				<h2 class="center" v-html="component_item.header"></h2>
				<p v-html="component_item.content"></p>
				<!-- FORM BODY -->
				<el-form ref="custom_form" :model="custom_form" :rules="custom_rules" label-position="top"
					:label-width="formLabelWidth" class="t-rightt">
					<div v-for="(item, index) in data_form" :key="index">
						<el-form-item :label="item.label" :prop="item.deal_form ? item.original_name : item.name">
							<div v-if="['el-radio', 'el-checkbox'].includes(item.type)" class="w-100">
								<el-checkbox-group v-model="custom_form[item.name]" @change="changeComponent"
									v-if="item.props.is_multiple_choice">
									<el-row>
										<el-col :span="6" v-for="checkbox in item.options"
											:key="'checkbox-' + checkbox.label" style="margin-right: 10px;">
											<component :is="item.type" v-bind="{ ...item.props, label: checkbox.label }"
												class="w-25">
												{{ checkbox.label }}
											</component>
										</el-col>
									</el-row>
								</el-checkbox-group>
								<el-radio-group v-model="custom_form[item.name]" v-else>
									<el-row>
										<el-col :span="6" v-for="radio in item.options" :key="'radio-' + radio.label"
											style="margin-right: 10px;">
											<component :is="item.type" v-bind="{ ...item.props, label: radio.label }"
												class="w-25 forma-cilent-info-radio">
												{{ radio.label }}
											</component>
										</el-col>
									</el-row>
								</el-radio-group>
							</div>
							<div v-else-if="item.type == 'el-upload'">
								<el-upload class="upload-demo w-50" action="#" :on-change="handleChange"
									:on-remove="handleRemove" :file-list="custom_form[item.name]" list-type="picture"
									:auto-upload="false" multiple :limit="5">
									<el-button size="small" type="primary">{{ $t('message.UploadFile') }}</el-button>
									<div slot="tip" class="el-upload__tip">{{ $t('message.UploadFileText') }}</div>
								</el-upload>
							</div>
							<div v-else>
								<component v-if="item.deal_form" :is="item.type" v-model="custom_form[item.original_name]"
									v-bind="{ ...item.props }" :placeholder="item.label" clearable class="w-100">
									<div v-if="item.type == 'el-select'">
										<el-option v-for="(option, o_index) in item.options" :key="o_index"
											:label="option.label" :value="option.value"></el-option>
									</div>
								</component>
								<component v-else :is="item.type" v-model="custom_form[item.name]"
									v-bind="{ ...item.props }" :placeholder="item.label" clearable class="w-100">
									<div v-if="item.type == 'el-select'">
										<el-option v-for="(option, o_index) in item.options" :key="o_index"
											:label="option.label" :value="option.value"></el-option>
									</div>
								</component>
							</div>
						</el-form-item>
					</div>
				</el-form>

				<p v-html="component_item.footer"></p>
				<div v-if="!isFormEmty" class="center">
					<el-button :loading="loadingButton" @click="submit()">Oтправлять</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
export default {
	data() {
		return {
			data_form: [],
			custom_form: {},
			custom_rules: {},
			formLabelWidth: '120px',
			component_item: {},
			loadingButton: false,
			updateFileList: [],
			loading: false,
		}
	},
	computed: {
		decryptedText() {
			if (this.$route.params.user_id) {
				const decoded = this.$CryptoJS.enc.Base64.parse(this.$route.params.user_id).toString(this.$CryptoJS.enc.Utf8);
				return this.$CryptoJS.AES.decrypt(decoded, "Secret goodone gocrm auth user id").toString(this.$CryptoJS.enc.Utf8);
			}
		},
		sourceID() {
			if (this.$route.query.source_id) {
				return parseInt(this.$route.query.source_id);
			}
		},
		isFormEmty() {
			if (Object.keys(this.custom_form).length == 0) {
				return true;
			}
			return false;
		}
	},
	created() {
		this.loading = true;
		if (this.$route.params.hasOwnProperty('uuid')) {
			this.fetchData(this.$route.params.uuid);
		}
	},
	methods: {
		...mapActions({
			getCustomForm: 'customForm/getCustomForm',
			save: 'clientForm/store'
		}),
		submit(close = true) {
			let formData = new FormData();

			formData.append('uuid', this.$route.params.uuid);
			formData.append('user_id', parseFloat(this.decryptedText));
			formData.append('source_id', this.sourceID);
			formData.append('form', JSON.stringify(this.custom_form));

            let hiddenInput = document.querySelector('input[name="product_id"]');
            if (hiddenInput && hiddenInput.value) {
                formData.append('product_id', hiddenInput.value);
            }

			if (_.size(this.updateFileList) > 0) {
				for (const key in this.updateFileList) {
					if (this.updateFileList.hasOwnProperty(key)) {
						const element = this.updateFileList[key];
						formData.append(`files[${key}]`, element);
					}
				}
			}

			this.$refs["custom_form"].validate((valid) => {
				if (valid && this.validateUplaodFiles()) {
					this.loadingButton = true;
					this.save(formData)
						.then((res) => {
							this.loadingButton = false;
							this.$notify({
								title: this.$t('message.success'),
								type: "success",
								offset: 130,
								message: this.$t('message.your_request_sent_successfully')
							});
							this.$refs["custom_form"].resetFields();
							this.updateFileList = [];
							// this.parent().listChanged();
							// if (close) this.close();
						})
						.catch((err) => {
							this.loadingButton = false;
							this.$alert(err);
						});
				}
			});
		},
		validateUplaodFiles() {
			if (_.find(this.updateFileList, function (o) { return (o.type != "image/jpeg" && o.type != "image/png" && o.type != "application/pdf") })) {
				this.$message({
					message: this.$t('message.The file must be JPG, PNG and PDF format'),
					type: 'warning',
					showClose: true
				});
				return false;
			}
			return true;
		},
		buildFormData(formData, data, parentKey) {
			if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
				Object.keys(data).forEach(key => {
					this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
				});
			} else {
				const value = data == null ? '' : data;
				formData.append(parentKey, value);
			}
		},

		fetchData: _.debounce(async function (uuid) {
            let query = { uuid: uuid };
            if (this.$route.query.product_id) {
                query.product_id = this.$route.query.product_id;
            };
			await this.getCustomForm(query).then((res) => {
				if (res.customForm) {
					this.component_item['header'] = res.customForm.header;
					this.component_item['content'] = res.customForm.content;
					this.component_item['footer'] = res.customForm.footer;
					this.setCustomFormData(res.customForm);
				}
			});
			this.loading = false;
		}, 500),
		setCustomFormData(data) {
			_.forEach(data.items, item => {
				this.regenerationForm(item);
			});
		},

		resizeIframe(obj) {
			obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
		},
		regenerationForm(form) {
			if ((form.type == 'el-upload') || (form.type == 'el-checkbox')) {
				this.$set(this.custom_form, form.name, []);
			}
			else if (form.type == 'el-switch') {
				this.$set(this.custom_form, form.name, false);
			}
			else {
				if (form.deal_form)
					this.$set(this.custom_form, form.original_name, '');
				else
					this.$set(this.custom_form, form.name, '');
			}

			if (form.required) {
				let rule = { required: form.required, message: this.$t('message.The_field_must_not_be_empty'), trigger: 'change' };
				if (form.deal_form)
					this.$set(this.custom_rules, form.original_name, [rule]);
				else
					this.$set(this.custom_rules, form.name, [rule]);
			}

			this.data_form.push(form);
		},
		handleChange(file) {
			this.updateFileList.push(file.raw);
		},
		handleRemove(file) {
			for (let i = 0; i < _.size(this.updateFileList); i++) {
				if (this.updateFileList[i].name === file.name) {
					this.updateFileList.splice(i, 1);
				}
			}
		},
		changeComponent(item, param) {
			if (item.type == 'el-select') {
				let obj = _.find(item.options, { 'value': this.custom_form[item.name] });
				this.$set(this.custom_form, item.name, obj.label);
			}
			else if (item.type == 'el-radio') {
				this.$set(this.custom_form, item.name, param.label);
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.loader {
	height: 100vh;
}
.customForm {
	width: 100%;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	text-align-last: left;
	margin: auto;
	max-width: 752px;
	min-width: 320px;

	h2 span {
		text-align: center;
	}
}

.center {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (min-width: 1000px) {
	.customForm {
		width: 800px;
	}
}

@media (max-width: 992px) {

	.timeline-items__right,
	.step-block-deals-from {
		border: none;
		box-shadow: none !important;
	}

	.customForm {
		background-color: white !important;
	}
}

.t-rightt .el-form-item__label {
	text-align: left;
	width: 100%;
}
</style>
